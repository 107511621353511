import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { defaultPalette } from 'const/palette'
import useThrottledMemo from 'hooks/useMemoThrottle'
import { throttle } from 'lodash'
import findIndex from 'lodash/findIndex'
import dynamic from 'next/dynamic'

// Function to trim punctuation from the ends of words
export const cleanWord = (word = '') => {
  const punctuation = /[!"#$%&'*+,-./:;<=>?@[\]^_`{|}~]$/g
  return word.trim().toLowerCase().replace(punctuation, '')
}

const ReactWordcloud = dynamic(() => import('react-wordcloud'), {
  ssr: false,
})

const colors = {}

function TagCloud({
  tags = [],
  theme,
  maxTags = 50,
  fontSizes = [30, 100],
  onWordClick,
  filteredWords = [],
}) {
  const palette = theme?.palette || defaultPalette
  const [size, setSize] = useState()
  const containerRef = useRef()
  const data = useThrottledMemo(
    () =>
      tags
        .flat()
        .map((item) => cleanWord(item))
        .filter((tag) => !filteredWords.includes(tag))
        .reduce((acc, tag) => {
          const index = findIndex(acc, { text: tag })
          if (index > -1) {
            acc[index].value += 1
          } else {
            acc.push({ text: tag, value: 1 })
          }
          return acc
        }, [])
        .sort((a, b) => b.value - a.value)
        .slice(0, maxTags),
    1000,
    [tags, maxTags, filteredWords],
  )

  const options = useMemo(
    () => ({
      rotations: 0,
      rotationAngles: [-90, 0],
      fontFamily: 'Arial',
      fontSizes,
      enableTooltip: false,
      enableOptimizations: true,
      deterministic: true,
      padding: 3,
      spiral: 'archimedean',
      scale: 'sqrt',
    }),
    [theme, fontSizes],
  )

  const callbacks = useMemo(
    () => ({
      getWordColor: ({ text }) => {
        if (colors[text]) return colors[text]
        const totalColors = Object.keys(colors).length
        const paletteIndex = totalColors % palette.length
        const color = palette[paletteIndex]
        colors[text] = color
        return color
      },
      onWordClick,
    }),
    [],
  )

  useEffect(() => {
    const handleResize = throttle(() => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect()
        setSize([width, height])
      }
    }, 100)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect()
      setSize([width, height])
    }
  }, [])

  return (
    <div className="h-full w-full" ref={containerRef}>
      {size && (
        <ReactWordcloud
          words={data}
          options={options}
          size={size}
          callbacks={callbacks}
        />
      )}
    </div>
  )
}

export default memo(TagCloud)
